<template>
  <div class="page">
    <PageTitle :title="title" :routes="routes" :border="false"></PageTitle>
    <keep-alive include="ProductList">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import PageTitle from '@/components/PageTitle'
export default {
  name: 'Categroy',
  components: {
    PageTitle
  },
  computed: {
    title() {
      let name = '商品列表'
      if (this.routes.length > 0) name = this.routes[this.routes.length - 1].name
      return name
    },
    ...mapState({
      routes: 'route'
    })
  },
  mounted() {
    // let path = this.$route.path
    // if (path.indexOf('/') === 0) path = path.substring(1)
    // const currentPage = {
    //   link: path,
    //   name: '商品列表'
    // }
    // this.$store.commit('changeRoute', currentPage)
  },
  beforeDestroy() {
    this.$store.commit('changeRoute', null)
  },
  beforeRouteUpdate (to, from, next) {
    let path = to.path
    if (path.indexOf('/') === 0) path = path.substring(1)
    const name = to.query.id ? 'ProductDetail' : to.name
    const chineseName = {
      ProductAdd: '添加类目',
      ProductPublish: '商品发布',
      ProductDetail: '商品详情'
    }
    const toPage = {
      link: path,
      name: chineseName[name]
    }
    this.$store.commit('changeRoute', toPage)
    next()
  }
}
</script>

<style scoped lang="scss">
.page {
  display: flex;
  flex-direction: column;
}
</style>
